@layer preIPS {
    [data-aid-version] img {
        max-width: 100%;
        width: auto;
    }

    .imageWrapper {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;

        p & {
            display: inline-grid;
        }

        & > * {
            grid-area: 1 / 1;

            &:first-child {
                z-index: 2;
            }
        }

        & img {
            width: 100%;
            height: auto;
        }
    }

    .loadImageItem {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        border: 1px solid var(--borderColorTertiaryy);
        cursor: pointer;

        p & {
            display: inline-grid;

            & img {
                height: 1em !important;
            }
        }

        & .container {
            container-type: inline-size;
            display: flex;
            justify-items: center;
            align-items: center;
            margin-block-end: var(--spacing32);
            grid-area: 1 / 1;
            padding: var(--spacing16);
            z-index: 1;
            color: var(--textColorPrimary);

            & .innerFlex {
                display: flex;

                & .i18nString {
                    color: var(--textColorPrimary);
                    font-weight: var(--fontWeightBold);
                    margin-inline-end: var(--spacing8);
                }

                & .title {
                    color: var(--accentColorLink);
                    font-style: italic;
                }
            }

            @container (min-width: 400px) {
                .innerFlex {
                    margin: 0 var(--spacing32);
                }

                .description {
                    display: flex;
                    flex-direction: row;
                }
            }

            & svg {
                color: var(--textColorPrimary);
                align-self: flex-start;
                margin-inline-end: var(--spacing16);
            }
        }
        & img {
            background-clip: content-box;
            background-color: var(--panelBackground);
            display: inline-block;
            margin-block-end: 0 !important; /* override margin from the global-template css. */
            grid-area: 1 / 1;
            height: auto !important;
            position: relative;
            width: 100% !important;
        }

        /* CSS to handle headshot images not showing up correctly when they have long titles */
        /* or when the user has turned off images */
        &[class*="headshot"] {
            & .title,
            & .i18nString,
            & img {
                /* none of these are needed when showing the headshot, and can mess up the layout if they are visible */
                display: none;
            }

            & .innerFlex,
            & svg {
                margin: 0;
            }

            & .container {
                padding: 0;
                margin-block: 0;
                background-color: var(--panelBackground);
                justify-content: center;
                border-radius: 100%;

                /* The following is to make the little circle stand out  */
                border: 1px solid var(--borderColorTertiary);
                height: 58px; /* the size of the headshot circle -2px */
                width: 58px; /* needed to make the border visible */
                position: relative;
                inset: 1px;
            }
        }

        /* The icon class comes from the content, it helps us know that this is a small centered image. */
        &:global(.icon) {
            width: 100%;

            & .container {
                align-content: center;
                justify-content: center;
                margin: 0;
                padding: unset;

                & .innerFlex {
                    flex-direction: column;
                    margin: 0 var(--spacing32);

                    & .i18nString {
                        color: var(--accentColorLink);
                    }

                    & .title {
                        color: var(--textColorPrimary);
                        font-style: italic;
                    }
                }

                & svg {
                    align-self: center;
                    color: var(--accentColorLink);
                    margin: 0;
                    margin-inline-end: 0;
                }
            }

            & img {
                padding-block: 0px;
            }
        }
    }

    .isAssociatedMedia {
        background-color: unset;
        border: none;
        color: var(--accentColorLink);
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;

        & .i18nString {
            color: var(--accentColorLink);
        }

        & > svg {
            margin-inline-end: var(--spacing8);
        }
    }

    .loading {
        inset-block-start: 0;
        inset-inline-start: 0;
    }

    @media print {
        .loadImageItem {
            display: none;
        }
    }
}
