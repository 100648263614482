.iconPointer {
    cursor: pointer;
}

[data-aid-version] .icon[class] {
    inset-inline-end: 0.25rem;
    position: fixed;
    text-indent: initial;
    transform: translateY(-0.15em);
    user-select: none;

    @media (min-width: 46rem) {
        inset-inline-end: 20px;
    }
}

.iconPointerButton {
    background-color: var(--background-color-level-current);
    border: none;
    cursor: pointer;
    display: inline-flex;
    padding: 0;
    position: relative;
    inset-block-start: var(--spacing8);
}

.Icon {
    position: relative;
    z-index: 1;
    color: var(--accentColorLink);
}

/* TODO: add theming for IconButton (eden-button/Icon) */
