.sidePanel {
    border-inline-end: none;
    max-height: calc(100vh - 44px);
    height: calc(100vh - 44px);
    inset-block-start: 44px;
    position: sticky;

    /* dvh doesn't work from some mobile browsers */
    /* examples ( < iOS 15 and < chrome 108 for Anadorid ) */
    @supports (height: 100dvh) {
        max-height: calc(100dvh - 44px); /* the dvh is necessary */
        height: calc(
            100dvh - 44px
        ); /* the dvh ensures content at the bottom of a panel is visible in mobile when the browser bar is visible. */
    }
}

/* CSS specific to iOS devices */
@supports (-webkit-touch-callout: none) {
    .sidePanel.closed,
    .floatingPanels.closed {
        display: none;
    }
}

.floatingPanels {
    display: grid;
    grid-template-columns: 320px 320px;
    pointer-events: none;

    & > * {
        pointer-events: auto;
    }
}

@media print {
    .sidePanel {
        display: none;
    }

    .floatingPanels {
        display: none;
    }
}
