.tableOfContents {
    position: relative;
    margin-block-end: var(--spacing16);
    flex-grow: 1;
}

.subItems {
    display: none;
    flex-direction: column;

    &.open {
        display: flex;
    }

    &.active {
        background: var(--background-color-level3);
    }
}

.item,
.sectionTitle,
.bookTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--spacing8);
    padding-inline-start: 0;
    min-height: 2.25rem;
    font-size: var(--fontSize14);
    cursor: pointer;
    line-height: var(--lineHeight);
    text-decoration: none;
    color: var(--textColorPrimary);
    outline: none;
    position: relative;

    @nest .hasQuickNav & {
        padding-inline-end: var(--spacing16);
    }

    & p:empty {
        display: none;
    }

    &:hover {
        text-decoration: none;
    }
}

.tableOfContents > .subItems > li > .sectionTitle,
.bookTitle {
    min-height: 3.25rem;
    line-height: var(--lineHeightLoose);
}

.item,
.sectionTitle {
    &:focus,
    &:hover,
    &:active {
        background: var(--background-color-hover, var(--grey3));

        @nest .active & {
            background: var(--background-color-hover, var(--grey2));
        }
    }

    &.item.active {
        background: var(--background-color-active, var(--highlightBlue));

        &:hover {
            background: var(--background-color-hover, var(--grey2));
        }

        /* Controls the little bar that shows which page the user is viewing */
        &::after {
            position: absolute;
            content: "";
            width: 4px;
            height: 100%;
            inset-inline-end: 0;
            background-color: var(--accentColorLink);
        }
    }

    &.sectionTitle.active:hover {
        background: var(--background-color-hover, var(--grey3));
    }
}

.bookTitle {
    cursor: default;

    & .itemTitle {
        padding-inline-start: var(--spacing32);
        font-weight: var(--fontWeightSemiBold);
        font-size: var(--fontSize18);
    }

    &:hover {
        color: var(--textColorPrimary);
    }
}

.itemTitle {
    padding-inline-start: var(--spacing16);
    position: relative;
}

.sectionTitle {
    & .itemTitle {
        position: static;
    }

    &.active {
        &:focus,
        &:hover,
        &:active {
            background: var(--background-secondary, var(--grey2));
        }
    }

    & svg {
        color: var(--textColorSecondary);
        position: absolute;
        top: 50%;
        inset-inline-end: 0;

        &:dir(ltr) {
            transform: translate(-50%, -50%);
        }

        &:dir(rtl) {
            transform: translate(50%, -50%);
        }
    }
}

.tableOfContents > .subItems > li > .sectionLabel {
    min-height: 1.875rem;
    cursor: default;
    position: sticky;
    inset-block-start: 0;
    z-index: 1;
    font-size: var(--fontSize11);
    font-weight: var(--fontWeightBold);
    padding: var(--spacing4);
    /* Background image becomes the hover color which is transparent in dark mode */
    background-image: linear-gradient(
        var(--background-color-hover, var(--grey3)),
        var(--background-color-hover, var(--grey3))
    );
    /* Background color becomes the solid color to prevent the above transparent color from letting content behind the sticky header show through in dark mode */
    background-color: var(--panelBackground);
}

.sectionDrawer {
    & .itemTitle {
        padding-inline-end: var(--spacing24);
    }
}

.pretitle,
.titleNumber,
.subtitle,
.seriesTitle {
    font-size: var(--fontSize11);
    color: var(--textColorSecondary);
    pointer-events: none;
}

.songNumber {
    font-weight: var(--fontWeightBold);

    &:after {
        content: ". ";
    }
}

.quickNav {
    align-items: center;
    border-inline-end: 1px solid #d0d3d3;
    cursor: pointer;
    display: inline-flex;
    flex-basis: 2.5rem;
    flex-shrink: 0;
    flex-direction: column;
    height: calc(100vh - 44px);
    inset-block-start: 0;
    justify-content: center;
    pointer-events: none;
    position: sticky;

    & li {
        align-items: center;
        display: inline-flex;
        flex: 0 1 auto;
        width: 100%;
        font-size: var(--fontSize13);
        justify-content: center;
        padding: 12px 0;
        pointer-events: auto;

        & button {
            background: none;
            border: none;
            color: var(--accentColorLink);
            cursor: pointer;
            font: inherit;
            padding: 4px;
        }
    }
}
