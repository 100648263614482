.innerAlert {
    display: flex;
    flex-direction: column;
    gap: var(--spacing16);
    max-width: 320px;
}

.buttons {
    display: flex;
    gap: var(--spacing16);
    justify-self: flex-start;
}
