.loadVideoButton {
    container-type: inline-size;
    font-size: var(--fontSize16);
    width: 100%;
    padding: var(--spacing20);
    margin-block-end: var(--spacing32);
    background-color: var(--panelBackground);
    border: 1px solid var(--borderColorTertiary);
    cursor: pointer;
    overflow: hidden;
    height: 0;
    padding-block-start: 56.25%;
    position: relative;

    & .innerButton {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    & .innerFlex {
        display: flex;
        margin: 0 var(--spacing32);
        color: var(--textColorPrimary);

        & .text {
            text-align: start;
        }

        & svg {
            align-self: flex-start;
            flex-shrink: 0;
            margin-inline-end: var(--spacing16);
        }

        & .i18nString {
            color: var(--textColorPrimary);
            font-weight: var(--fontWeightBold);
            margin-inline-end: var(--spacing8);
        }

        & .title {
            color: var(--accentColorLink);
            font-style: italic;
        }

        & .description {
            color: var(--textColorPrimary);
        }
    }

    @container (max-width: 400px) {
        .innerFlex {
            margin: 0 var(--spacing16);
        }
    }
}

.posterFallback {
    background-color: var(--panelBackground);
}

.isAssociatedMedia {
    background-color: unset;
    border: none;
    color: var(--accentColorLink);
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;

    & .i18nString {
        color: var(--accentColorLink);
    }

    & > svg {
        margin-inline-end: var(--spacing8);
    }
}

.video {
    width: 100%;

    & img[class] {
        padding: 0;
    }
}

@media print {
    /* Hide global class "video" as figure or video elementes when printing */
    figure:global(.video),
    .video[class],
    .placeholder,
    .loadVideoButton {
        display: none;
    }
}
