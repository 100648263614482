.callout[class] {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    max-height: 55vh;
    background-color: var(--background-color-level-current);
    border: 1px solid var(--borderColorTertiary);

    &.blockStart {
        inset-inline-start: 0;
        inset-block-start: calc(100% + 20px);
    }
    &.blockEnd {
        inset-inline-start: 0;
        inset-block-end: calc(100% + 20px);
    }
    &.inlineStart {
        inset-inline-start: calc(100% + 20px);
        inset-block-start: 0;
    }
    &.inlineEnd {
        inset-inline-end: calc(100% + 20px);
        inset-block-start: 0;
    }
    &.none {
        inset-inline-start: 0;
        inset-block-start: calc(100% + 10px);
    }

    @media (width >= 46em) {
        & .arrow {
            position: absolute;

            &.blockStart {
                inset-block-start: -18px;
                inset-inline-start: 16px;
            }

            &.blockEnd {
                inset-block-end: -18px;
                inset-inline-start: 16px;
                transform: rotate(180deg);
            }

            &.inlineStart {
                inset-block-start: 16px;
                inset-inline-start: -18px;
                transform: rotate(-90deg);

                &:dir(rtl) {
                    transform: rotate(90deg);
                }
            }

            &.inlineEnd {
                inset-block-start: 16px;
                inset-inline-end: -18px;

                &:dir(ltr) {
                    transform: rotate(90deg);
                }

                &:dir(rtl) {
                    transform: rotate(-90deg);
                }
            }

            &.none {
                display: none;
            }
            &:before,
            &:after {
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                z-index: 0;
                inset-inline-start: 50%;
            }

            &:after {
                border-block-end-color: var(--background-color-level-current);
                border-width: 9px;
                margin-inline-start: -8px;
            }

            &:before {
                border-block-end-color: var(--borderColorTertiary);
                border-width: 11px;
                margin-inline-start: -10px;
                margin-block-start: -4px;
            }
        }
    }
}
