.panelHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0 var(--spacing8);
    inset-block-start: 0;
    height: 44px;
    width: 100%;
    background-color: var(--backgroundColorLevel2);
    border-block-end: 1px solid var(--panelBorders);
    text-align: center;
    line-height: var(--lineHeightLoose);
    font-family: var(--sans);
    font-size: var(--fontSize14);
    color: var(--textColorPrimary);
    z-index: 20;
    /* transform: scale(1); */

    @media print {
        display: none;
    }
}
