.bookmarkIcon {
    color: var(--accentColorLink);
    cursor: pointer;
    inset-inline-start: 20px;
    position: absolute;
    text-indent: initial;

    & svg {
        color: var(--accentColorLink);
    }
}

[data-aid-version] :global(#bookmarkModal).bookmarkModal {
    position: absolute;
    width: 100%;
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0.5em;
    line-height: var(--lineHeight);
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(83, 87, 91, 0.3);
    z-index: 1;

    & div {
        display: flex;
        background-color: var(--background-color-level-5);
        border-color: var(--background-color-level-5);
    }

    &:global(.dragging) svg.bookmarkModalIcon {
        cursor: grabbing;
    }

    & .bookmarkModalBody {
        align-items: center;
        display: flex;
        font-size: 16px;
        font-family: var(--sans);
        text-transform: none;
        text-indent: initial;
        width: 100%;
    }

    & .bookmarkModalIcon {
        display: inline-flex;
        margin: 0 0.75em;
        font-size: 24px;
        cursor: grab;
        touch-action: none; /* necessary to prevent scrolling when trying to drag the modal */

        & path {
            fill: var(--accentColorLink);
        }
    }

    & .bookmarkName {
        text-align: start;

        & span {
            font-size: 14px;
            color: var(--textColorSecondary);
            letter-spacing: 0;
        }
    }

    & span.modalTitle,
    & span.modalTitle {
        text-transform: none;
        font-family: var(--sans);
        font-weight: var(--fontWeightSemiBold);
        font-size: 18px;
        color: var(--textColorPrimary);
        padding: 0;
        letter-spacing: 0;
        text-align: start;
        display: block;
        font-style: none;
    }

    & .input {
        border: none;
        width: 100%;
        height: 45px;
        outline: none;
        font-size: 18px;
        color: var(--textColorPrimary);
        background-color: var(--background-color-level-current);

        &::-webkit-input-placeholder {
            color: var(--textColorTertiary);
        }
    }
}
