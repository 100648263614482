/* Header */
.sidePanelHeader {
    flex-shrink: 0;
}

.headerTitle[class] {
    color: var(--textColorPrimary);
}

.headerSpace {
    width: var(--spacing24);
}
