.calloutChild {
    display: flex;
    flex-wrap: wrap;
    max-height: 55vh;
    overflow: auto;
    padding: var(--spacing16);
    position: relative;
    user-select: none;
    width: 290px;

    & header {
        display: flex;
        align-items: center;
        width: 100%;
        margin-inline-start: var(--spacing8);
    }

    & div {
        display: block;
    }

    & .closeButton {
        margin-inline-start: auto;
    }
}
