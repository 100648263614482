dialog.overlay {
    /* these are used to make the reader body and side panels adjust their height and width appropriately */
    --overlay-header-height: 106px;
    --overlay-height: 100vh;
    --overlay-width: 100vw;
    --overlay-content-height: calc(
        var(--overlay-height) - var(--overlay-header-height)
    );
    max-width: none; /* Override eden-platform-modal forced width */

    @media (width >= 25rem) {
        --overlay-height: calc(100vh - 6rem);
        --overlay-width: calc(100vw - 6rem);
        --overlay-content-height: calc(
            var(--overlay-height) - var(--overlay-header-height)
        );
    }

    & > div:last-child {
        height: var(--overlay-height);
        width: var(--overlay-width);
    }

    & .overlayBody {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    & .header {
        align-items: center;
        background-color: var(--background-color-level-current);
        display: grid;
        flex: none;
        grid-template-columns: 1fr auto 1fr;
        grid-template-rows: 1fr;
        height: 60px;
        padding: 12px 16px;
    }

    --linkBarBackground: var(--grey2);
    @media (prefers-color-scheme: dark) {
        --linkBarBackground: var(--backgroundColorLevel3);
    }

    & .crossLinks {
        background: var(--linkBarBackground);
        flex: none;
    }

    & .overlayContent {
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
    }

    & .hasBorder {
        border: none;
        border-block-end: solid 1px var(--borderColorTertiary);
    }

    & .backButton {
        margin-inline-end: auto;
    }

    & .overlayTitle {
        font-family: var(--sans);
        font-size: var(--fontSize18);
        font-weight: var(--fontWeightSemiBold);
    }

    & .modalControls {
        margin-inline-start: auto;
    }
}
