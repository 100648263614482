.notifications {
    height: fit-content;
    inset-block-start: calc(
        44px + var(--spacing8)
    ); /* header height + spacing */
    margin-block-start: var(--spacing8);
    margin-inline: auto var(--spacing8);
    max-width: 31rem;
    position: sticky;
    width: calc(100vw - var(--spacing32));
    z-index: 40;
}

.layout {
    display: flex;
    flex-direction: column;
    min-height: 16rem;
}

.title {
    padding-block-end: var(--spacing16);
}

.contentContainer {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 0;

    & .image {
        align-self: flex-start;
        padding-inline-end: var(--spacing16);
    }

    & .content {
        align-items: flex-start;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        & .description {
            margin-block-end: var(--spacing16);
        }

        & .buttonsBar {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-self: flex-end;

            & > * {
                margin-inline-start: var(--spacing16);
                margin-block-start: var(--spacing8);
            }
        }
    }
}

.navButtonsBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-block-start: var(--spacing16);

    & .navigationButtons {
        width: auto;
        flex-grow: 0;
    }
}
