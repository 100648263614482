.readerViewHeader[class] {
    align-items: center;
    background-color: var(--backgroundColorLevel2);
    border-block-end: 1px solid var(--panelBorders);
    color: var(--textColorPrimary);
    display: grid;
    font-family: var(--sans);
    font-size: var(--fontSize14);
    grid-row: 1 / span 1;
    grid-template-columns: auto 3fr 1fr;
    height: 44px;
    inset-block-start: 0;
    line-height: var(--lineHeightLoose);
    margin: 0;
    padding: 0 var(--spacing8);
    padding-inline: var(--spacing8) 0;
    position: sticky;
    text-align: center;
    width: 100%;
    z-index: 20;

    @media print {
        display: none;
    }

    @media (width >= 46em) {
        grid-template-columns: 1fr 3fr 1fr;
    }

    & .iconGroupStart {
        transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
        justify-self: start;
    }

    & .iconGroupEnd {
        display: flex;
        gap: var(--spacing4);
        justify-self: end;
        padding-inline-end: var(--spacing20);
        height: inherit;

        & .activePanel[class] {
            background-color: var(
                --button-navigation-background,
                var(--highlightBlue)
            );
            color: var(--textColorPrimary);
            transition: background-color 0.3s;
            position: relative;
            height: inherit;
            border-radius: unset;
            border-radius: 0;

            &:hover {
                background-color: var(--grey3);
                @media (prefers-color-scheme: dark) {
                    background-color: var(--whiteTransparency60);
                }
            }

            &:after {
                height: 4px;
                background-color: var(--accentColorPrimary);
                position: absolute;
                content: "";
                inset-inline: 0;
                inset-block-end: 0;
            }
        }

        & button {
            border-radius: 0;
            padding-inline: var(--spacing16);
        }
    }

    & .contentTitle {
        cursor: pointer;
        display: block;
        justify-self: center;
        max-width: 31rem;
        min-width: 0;
        overflow: hidden;
        position: relative;
        text-align: center;
        text-overflow: ellipsis;
        transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
        white-space: nowrap;
        width: 100%;

        &::after {
            content: "";
            width: 5%;
            position: absolute;
            inset-block: 0;
            inset-inline-end: 0;

            &:dir(ltr) {
                background: linear-gradient(
                    to left,
                    var(--panelBackground) 0,
                    rgba(255, 255, 255, 0) 100%
                );
            }

            &:dir(rtl) {
                background: linear-gradient(
                    to right,
                    var(--panelBackground) 0,
                    rgba(255, 255, 255, 0) 100%
                );
            }
        }

        & .catalogTitle[class] {
            color: var(--textColorSecondary);
            font-size: var(--fontSize11);
        }
    }
}
