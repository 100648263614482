.toolbarTrack {
    --initialOffset: 64px;
    height: calc(100% - var(--initialOffset));
    inset-block-start: var(--initialOffset);
    inset-inline-start: 12px;
    position: absolute;

    @media (width >= 46rem) {
        inset-inline-start: -8px;
    }
}

.floatingAnnotationToolbar,
.toolbarMark {
    inset-block-start: var(--toolbar-top, 0px);
    position: absolute;
}

.floatingAnnotationToolbar {
    align-items: center;
    background-color: var(--panelBackground);
    border: 1px solid var(--borderColorTertiary);
    border-radius: 20px;
    box-shadow: var(--boxShadowRaised);
    display: flex;
    flex-direction: column;
    padding-block: var(--spacing8);
    width: 30px;
    z-index: 1;

    @media (width > 46rem) {
        width: 40px;
    }

    &:focus-visible {
        outline: none;
    }

    &.sticky {
        inset-block-start: var(--toolbar-sticky-top, 0px);
        position: sticky;
        transition: unset;
    }

    @media print {
        display: none;
    }
}

.singleItem {
    justify-content: center;
    height: 30px;
    padding-block: 0;

    @media (width > 46rem) {
        height: 40px;
    }
}

.disableTransition {
    transition: unset;
}

.fatDivider[class] {
    margin-block: var(--spacing4);
}

.fatIconButton[class] {
    @media (width <= 46rem) {
        margin-block: 4px;
        min-width: 26px;
        min-height: 26px;
        font-size: 18px;
    }

    &:hover {
        background-color: var(--grey5);

        @media (prefers-color-scheme: dark) {
            background-color: var(--whiteTransparency60);
        }
    }

    &:active {
        background-color: var(--button-icon-background-active, var(--grey10));
    }

    &:disabled {
        color: var(--button-icon-color-disabled, var(--grey15));

        &:hover {
            color: var(--button-icon-color-disabled, var(--grey15));
        }
    }
}

.defaultToolbarMarkOption[class] {
    font-size: var(--fontSize16);
    margin-block: var(--spacing4);
    padding-block-start: 1px;
    width: var(--spacing16);

    @media (width > 46rem) {
        font-size: var(--fontSize22);
        margin-block: 3px;
        padding-block-start: 0.15rem;
        width: 22px;
    }
}

.markOption {
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-family: var(--serif);
    margin-block: 3px;

    & mark {
        color: var(--textColorPrimary);
        display: block;
        font-size: var(--fontSize16);
        font-weight: var(--fontWeightLight);
        height: 1.25rem;
        padding-block-start: 0.1rem;
        line-height: 1.25rem;
        position: relative;
        text-align: center;
        width: var(--spacing16);

        &.markOptionHighlight {
            border-radius: 2px;
            width: 1.25rem;

            @media (width > 46rem) {
                width: var(--spacing24);
            }
        }

        @media (width > 46rem) {
            font-size: var(--fontSize20);
            height: var(--spacing24);
            line-height: var(--spacing24);
            padding-block-start: 0.1rem;
            width: 20px;
        }

        &.active {
            pointer-events: none;

            &::after {
                background-color: var(--textColorPrimary);
                border-radius: var(--spacing4);
                content: "";
                height: var(--spacing4);
                inset-block-start: 40%;
                inset-inline-start: 95%;
                position: absolute;
                width: var(--spacing4);

                @media (width > 46rem) {
                    inset-inline-start: 105%;
                }
            }
        }
    }
}

.highlightMenuColor {
    appearance: none;
    border-radius: 19px;
    border: none;
    cursor: pointer;
    display: block;
    height: 19px;
    margin-block: 6px;
    padding: 0;
    position: relative;
    width: 19px;

    &.active {
        pointer-events: none;

        &::after {
            border: 1px solid var(--textColorPrimary);
            border-radius: 23px;
            content: "";
            display: block;
            height: 23px;
            inset-block-start: -2px;
            inset-inline-start: -2px;
            position: absolute;
            width: 23px;
        }
    }

    @media (width > 46rem) {
        margin-block: var(--spacing4);
    }
}

.modalNoHeader > div {
    box-shadow: var(--boxShadowRaised);
}
