/* prettier-ignore-file */
/* Locally there are no prettier errors, but when running in the pipeline there are. So seeing if the above will let us get through. */
:root {
    --panel-width: 320px;
    --panel-offset: var(--panel-width);
}

.activeVerse {
    background: var(--background-active-verse, lightyellow);
    padding: var(--spacing8);
    padding-block-end: 0.65em;
    margin: -var(--spacing8);
    margin-block-end: 0;
    transition: background 0.5s ease;
}

.reader {
    display: grid;
    font-family: var(--sans);
    grid-template-columns: var(--panel-width) 100%;
    min-height: var(--overlay-content-height, 100vh);
    position: relative;
    inset-inline-start: calc(var(--panel-width) * -1);
}

.mask {
    grid-column: 2 / span 1;
}

.sidePanelMask[class] {
    z-index: 1;
}

.contentArea {
    background-color: var(--background-color-level-current);
    color: var(--textColorPrimary);
    display: grid;
    font-family: var(--serif);
    grid-column: 2 / span 1;
    grid-template-columns: 100% var(--panel-offset);
    line-height: var(--lineHeight);
    max-width: var(--overlay-width, 100%);
    position: relative;

    & > * {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
    }

    @media (width >= 46em) {
        min-width: 640px;
    }

    & .contentWrapper {
        transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    & .hideSelection [data-active-mark="true"]::selection,
    & .hideSelection [data-active-mark="true"] *::selection {
        background-color: transparent;
    }

    & .traversalLink {
        display: none;
        margin-inline: var(--spacing8);
        pointer-events: none;
        transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
        width: 40px;
        z-index: 25;

        & a {
            box-sizing: content-box;
            color: var(--textColorPrimary);
            cursor: pointer;
            height: 40px;
            inset-block-start: 50%;
            pointer-events: all;
            position: sticky;
            transition: color 0.25s ease;

            &:hover {
                color: var(--accentColorLink);
            }
        }

        @media (width >= 46em) {
            display: flex;
        }
    }

    & .nextLink {
        justify-self: end;
    }

    & .prevLink {
        justify-self: start;
    }

    & .audioPlayer {
        align-self: end;
        inset-block-end: 0;
        position: fixed;
        width: 100%;

        & .button,
        & .player {
            transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
        }

        & .player {
            background: var(--background-color-level-current);
            box-shadow: 0 -2px 5px 0 rgba(74, 74, 74, 0.1);
            width: 100%;

            & > div {
                margin: 0 auto;
                max-width: 640px;

                @media (width >= 80em) {
                    max-width: 800px;
                }
            }
        }
    }

    & book-title {
        transition: all 1s ease;
    }

    & .mobileNav {
        align-self: end;
        background: var(--panelBackground);
        border-block-start: 1px solid var(--borderColorTertiary);
        display: flex;
        height: 44px;
        inset-block-end: 0;
        justify-content: space-around;
        position: sticky;
        transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);

        & .mobileIcon {
            align-items: center;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 50%;

            &.mobilePrev:dir(ltr),
            &.mobileNext:dir(rtl) {
                border-inline-end: 1px solid var(--borderColorTertiary);
            }
        }

        @media (width >= 46em) {
            display: none;
        }
    }

    & .navButton {
        &:hover[class] {
            color: var(--accentColorLink);
        }

        &:active[class] {
            color: var(--accentColorLink);
            background-color: var(
                --button-icon-background-active,
                var(--grey40)
            );
        }

        &:disabled {
            color: var(--button-icon-color-disabled, var(--grey20));
            background-color: var(--button-background-disabled, transparent);
        }
    }
}

.printIcon {
    display: none;

    @media (width >= 46em) {
        display: block;
        min-width: 10px;
        min-height: 10px;
    }
}

:global(#floatingAnnotationToolbar) {
    transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Panels */
.tableOfContentsPanel {
    border-inline-start: none;
    grid-row: 1 / span 2;
    inset-block-start: 0;
    transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 30;
}

.sidePanelManager {
    grid-column: 2 / span 1;
    transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 25;
    top: 44px;

    @media (width >= 60em) {
        z-index: 25;
    }
}

/* Panel Movement */
.tableOfContentsPanelOpen {
    &:not(.hideMobileTableOfContents) {
        --inline-start-panel-offset: var(--panel-offset);

        &:dir(rtl) {
            --inline-start-panel-offset: calc(var(--panel-offset) * -1);
        }
    }

    @media (width >= 80rem) {
        --inline-start-panel-offset: var(--panel-offset);

        &:dir(rtl) {
            --inline-start-panel-offset: calc(var(--panel-offset) * -1);
        }
    }
}

.sidePanelManagerOpen {
    --inline-end-panel-offset: calc(var(--panel-offset) * -1);

    &:dir(rtl) {
        --inline-end-panel-offset: var(--panel-offset);
    }

    @media (width >= 60em) and (width < 63em) {
        /* --annotation-toolbar-offset is just y = mx + b.
           * the calculation for the slope is the simplification of the following:
           * 28 / (1008 - 960)
           * maxOffset / (maxClientWidth - minClientWidth)
           */
        --annotation-toolbar-offset: calc(-7 / 12 * (100vw - 960px) + 28px);

        &:dir(rtl) {
            --annotation-toolbar-offset: calc(7 / 12 * (100vw - 960px) - 28px);
        }
    }
}

.tableOfContentsPanelOpen,
.sidePanelManagerOpen {
    @media (width >= 60rem) {
        --centered-offset: calc(var(--inline-end-panel-offset, 0px) / 2);
        --content-title-offset: var(--centered-offset);
        --content-body-offset: var(--centered-offset);
        --next-nav-offset: var(--inline-end-panel-offset);
        --mobile-nav-offset: var(--inline-end-panel-offset);
        --audio-button-offset: var(--inline-end-panel-offset);
    }

    @media (width >= 80rem) {
        --centered-offset: calc(
            (
                    var(--inline-start-panel-offset, 0px) +
                        var(--inline-end-panel-offset, 0px)
                ) / 2
        );
        --content-title-offset: var(--centered-offset);
        --content-body-offset: var(--centered-offset);
        --icon-group-start-offset: var(--inline-start-panel-offset);
        --prev-nav-offset: var(--inline-start-panel-offset);
    }

    & .tableOfContentsPanel {
        transform: translateX(var(--inline-start-panel-offset, 0px));
    }

    & .iconGroupStart {
        transform: translate(var(--icon-group-start-offset, 0px));
    }

    & .contentTitle {
        transform: translateX(var(--content-title-offset, 0px));
    }

    & .contentWrapper {
        transform: translateX(var(--content-body-offset, 0px));
    }

    & .sidePanelManager {
        transform: translateX(var(--inline-end-panel-offset, 0px));
    }

    & .mobileNav {
        transform: translateX(var(--mobile-nav-offset, 0px));
    }

    & .nextLink {
        transform: translateX(var(--next-nav-offset, 0px));
    }

    & .prevLink {
        transform: translateX(var(--prev-nav-offset, 0px));
    }

    & .audioPlayer {
        & .button {
            transform: translateX(var(--audio-button-offset, 0px));
        }

        & .player {
            transform: translateX(var(--content-body-offset, 0px));
        }
    }

    & :global(#floatingAnnotationToolbar) {
        transform: translateX(var(--annotation-toolbar-offset, 0px));
    }
}

@media print {
    html,
    body {
        overflow: visible;
        position: relative;
        display: block;
        height: auto;
    }

    video,
    .traversalLink {
        display: none !important;
    }

    img {
        break-inside: avoid;
        max-width: 100%;
        max-height: 100%;
    }

    .locked {
        display: block;
        overflow: visible;
    }

    .drawerWrapperWrapper,
    .contentArea .mobileNav,
    .icon,
    svg,
    span[class="page-break"],
    .loadingScreen {
        display: none;
    }

    .reader {
        min-height: initial;
        position: static;
        display: block;
        transform: initial;
    }

    .contentArea {
        background-color: var(--white);
        color: var(--black);
        display: block;
        margin: 0;
        width: 100%;
    }

    .narrowReader {
        padding: 0;
    }

    /* This rule is for Firefox/Safari printing w/o it will not center the content */
    .tableOfContentsPanelOpen .contentWrapper,
    .sidePanelManagerOpen .contentWrapper {
        --content-body-offset: 0px;
        transition: transform 0s;
    }
}

.sidePanelBottomBuffer {
    padding-block-end: 70px;

    /* dvh doesn't work from some mobile browsers */
    /* examples ( < iOS 15 and < chrome 108 for Anadorid ) */
    @supports (height: 100dvh) {
        padding-block-end: 0px;
    }
}
