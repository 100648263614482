.mask {
    position: absolute;
    background: rgba(13, 15, 16, 0);
    height: 100%;
    inset: 0;
    pointer-events: none;
    transition: background-color 400ms cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 30;

    &.active {
        background: rgba(13, 15, 16, 0.5);
        pointer-events: auto;
    }

    & + div {
        display: none;
    }
}

@media print {
    .mask {
        display: none;
    }
}
