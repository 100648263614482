/* The ".modalNoHeader .modalNoHeader" is necessary to isolate the header of the Eden component.
The whole modal component and its heading will have the same className. */
.modalNoHeader .modalNoHeader {
    display: none;
}

.modal,
.modalNoHeader {
    & > div:last-child {
        background-color: var(--background-color-level-current);
        border-color: var(--borderColorTertiary);
        overflow: visible;
    }
}
