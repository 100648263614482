.floatingPanel {
    height: calc(100vh - 44px);
    inset-block-start: 44px;
    z-index: 25;
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);

    /* dvh doesn't work from some mobile browsers */
    /* examples ( < iOS 15 and < chrome 108 for Anadorid ) */
    @supports (height: 100dvh) {
        height: calc(100dvh - 44px);
    }
}

.slideIn {
    grid-column: 2 / span 1;
    transform: translateX(-320px);

    &:dir(rtl) {
        transform: translateX(320px);
    }

    &.offScreen {
        transform: translateX(0);
    }
}

.associatedmedia figure {
    margin: 0px var(--spacing12) var(--spacing16);
    font-family: var(--sans);

    &:first-child {
        margin-block-start: var(--spacing10);
    }
}
