.startHandle,
.endHandle {
    position: absolute;
    display: none;
    content: "";
    border-radius: 50%;
    background-color: var(--accentColorPrimary);
    user-select: none;
    justify-content: center;
    align-items: center;
    touch-action: none;
}

.startHandle {
    border-start-end-radius: 0;
}

@media (any-pointer: coarse) {
    .startHandle::before,
    .endHandle::after {
        content: "";
        position: absolute;
        inset: -10px;
    }
}

.endHandle {
    border-start-start-radius: 0;
}

.dragging {
    pointer-events: none;
}
