.sidePanel {
    background: var(--panelBackground);
    &[class] {
        border: 1px solid var(--panelBorders);
        border-width: 0 1px;
    }
    border-block: none;
    display: flex;
    flex-direction: column;
    height: var(--overlay-content-height, 100vh);
    position: sticky;
    transform: scale(1);
    width: 320px;

    /* dvh doesn't work from some mobile browsers */
    /* examples ( < iOS 15 and < chrome 108 for Android ) */
    @supports (height: 100dvh) {
        height: var(
            --overlay-content-height,
            100dvh
        ); /*the dvh is necessary to ensure content at the bottom of a panel shows up in certain cases in mobile when the browser bar is visible */
    }
}

@media print {
    .sidePanel {
        display: none;
    }
}
