.positionByRange {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;

    &.hidden {
        opacity: 0;
    }

    & > * {
        user-select: none;
    }
}
