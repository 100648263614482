.studySetsButton[class][class] {
    min-height: unset;
    padding-inline: var(--spacing8);

    &.noActiveSet {
        min-width: 2rem;

        & div {
            display: none;
        }
    }

    & svg {
        font-size: 1.5rem;

        &:dir(rtl) {
            transform: scaleX(-1);
        }
    }

    & div {
        font-size: var(--fontSize14);
        max-width: 8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media (width <= 46em) {
        min-width: 2rem;

        & div {
            display: none;
        }
    }
}
