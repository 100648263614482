.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: var(--fontSize18);
    justify-content: center;
    margin: var(--spacing128) auto;
    padding: var(--spacing24);
    position: relative;
    text-align: center;
    text-wrap: pretty;

    .title {
        margin-bottom: var(--spacing32);
    }

    .errorCode {
        color: var(--textColorSecondary);
        margin-bottom: var(--spacing8);
    }

    .details {
        margin-bottom: var(--spacing16);
    }
}
