.typeaheadTagListModal {
    padding: var(--spacing8) var(--spacing16) var(--spacing16);
    width: 19rem;

    @media (width > 46rem) {
        width: 25rem;
    }

    & header {
        border-block-end: 1px solid var(--borderColorTertiary);
        display: flex;
        padding-inline-start: var(--spacing8);
        padding-block-end: var(--spacing8);

        & .modalTitle {
            padding-block: 12px 0;
        }

        & .modalButtons {
            align-items: center;
            margin-inline-start: auto;
            display: flex;
        }
    }

    & input {
        background-color: var(--background-color-level-current);
        color: var(--textColorPrimary);
    }

    & input::placeholder {
        color: var(--textColorSecondary);
    }
}

.notebookIcon,
.tagIcon {
    color: var(--icon-secondary, var(--grey20));
}

.tag {
    font-family: var(--sans);
    margin: var(--spacing4);
    overflow: hidden;
}

.tagText {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tagList {
    padding: var(--spacing24) var(--spacing8) 0;

    & div div {
        & div {
            border: none;
        }

        & div ul {
            background-color: var(--background-color-level-current);

            & li button {
                color: var(--textColorPrimary);

                & mark {
                    color: var(--textColorSecondary);
                }
            }
        }
    }
}
