.panelContent {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    overflow: auto;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
    background-color: var(--panelBackground);
    scrollbar-color: var(--scrollbar-thumb-primary, auto)
        var(--panelBackground, auto);
}

.webkitScrollbar {
    &::-webkit-scrollbar {
        width: 14px;
    }

    &::-webkit-scrollbar-button {
        background-color: auto;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--panelBackground);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-thumb-primary, var(--grey10));
        outline: 1px solid var(--scrollbar-thumb-primary, var(--grey10));

        &:hover {
            background-color: var(--scrollbar-thumb-secondary, var(--grey20));
        }
    }
}
