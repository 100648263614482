.backToAll {
    font-size: var(--fontSize16);
    font-weight: var(--fontWeightNormal);

    & .backText {
        color: var(--textColorPrimary);
        display: flex;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        align-items: center;

        & .backIcon {
            display: flex;
            font-size: var(--fontSize30);
        }

        & .backToLink {
            flex: 2;
            line-height: var(--lineHeight);
            max-width: 215px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.tocWrapper {
    display: flex;
    flex-direction: row;
}

.tocPanelBottomBuffer {
    background-color: var(--panelBackground);
    padding-block-end: 70px;

    /* dvh doesn't work from some mobile browsers */
    /* examples ( < iOS 15 and < chrome 108 for Anadorid ) */
    @supports (height: 100dvh) {
        padding-block-end: 0px;
    }
}

.hiddenPlatformFooter {
    display: none;
}
