.printOptions {
    padding-block: var(--spacing8);
    position: relative;
}

.printButtons {
    display: flex;
    justify-content: flex-end;
    padding-block-end: var(--spacing8);

    & button {
        margin-inline: var(--spacing8);
    }
}
