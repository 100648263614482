.menuLabel[class] {
    margin-block-end: var(--spacing20);

    & > div {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        & div {
            hyphens: auto;
            word-break: break-word;
        }

        & span.children {
            align-items: center;
            display: flex;
            height: var(--spacing16);
            justify-content: center;
            min-width: var(--spacing32);
        }
    }

    &.disabled div {
        color: var(--button-icon-color-disabled, var(--grey15));
    }
}
