dialog.printModal {
    background: var(--background-color-level-current);
    & > div:nth-child(3) {
        max-width: 400px;
        min-width: 300px;
        overflow: hidden;
        padding: var(--spacing8);

        & header {
            padding: var(--spacing8);
        }
    }
}

@media print {
    dialog.printModal {
        display: none;
    }
}
