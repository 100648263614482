:global(#app) .listTile[class][class] {
    color: var(--textColorPrimary);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & h6:first-of-type {
        color: var(--red30);
        @media (prefers-color-scheme: dark) {
            color: var(--red10);
        }
    }

    & h4 {
        color: var(--textColorPrimary);
    }

    &:hover h4 {
        color: var(--accentColorLink);
    }

    & hr {
        display: block;
    }

    & > div > div {
        min-height: unset;

        & > div:not(:first-child) > div {
            color: var(--textColorPrimary);
        }
    }
}
