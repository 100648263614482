.innerWrapper {
    padding-block-start: var(--spacing10);
    display: flex;
    overflow-x: auto;

    @media (width >= 46em) {
        width: 100%;
        flex-wrap: wrap;
    }
}

.tag {
    align-items: center;
    display: inline-flex;
    margin-block-end: var(--spacing10);
    margin-inline-start: var(--spacing10);
    max-width: 100%;
    vertical-align: baseline;

    & span {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
