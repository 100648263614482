.noInternetRelatedContent {
    font-family: var(--sans);
    padding: 11rem 2rem 2rem;
    text-align: center;
}

.noInternetRelatedContentTitle {
    font-weight: 600;
    margin: 0.4em;
}

.noInternetRelatedContentText {
    font-size: var(--fontSize14);
    line-height: 1.4;
    margin: 0;
}
