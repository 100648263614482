@layer preIPS {
    h1 {
        font-size: var(--fontSize30);
    }

    .renderFrame[class] {
        --minimum-content-width: 272px;
        margin: 0 auto;
        max-width: 40rem;
        min-width: var(--minimum-content-width);
        padding-block: 0 var(--spacing64);
        padding-inline: 3.5rem var(--spacing32);
        width: var(--overlay-width, 100%);

        /* This is used to create a containing block so
        icons and page numbers can be position fixed. */
        transform: scale(1);

        /* START iOS 14.x fix */
        /* iOS 14.x doesn't seem to support custom properties in padding-block/inline */
        /* iOS 15.x supports it just fine so we can remove this when iOS 14.x is more phased out */
        padding: 0 var(--spacing32) var(--spacing64);
        padding-inline-start: 3.5rem;
        /* END iOS 14.x fix */

        & .archivedAlert {
            inset-inline-start: -2rem;
            margin-block-start: 1.5rem;
            max-width: 40rem;
            min-width: var(--minimum-content-width);
            position: relative;
            width: calc(100% + 4rem);

            & h4 {
                margin-block-end: 0.5rem;
            }
        }

        @media (width >= 46em) {
            padding: 0 var(--spacing64) var(--spacing16);
        }
    }

    @media print {
        .renderFrame[class] {
            max-width: unset;
            overflow-y: visible;
            padding-inline: 0;
        }
    }
}
