.modalControls {
    align-items: center;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr auto;
    grid-column-gap: var(--spacing8);

    & .closeButton,
    & .closeButton:hover {
        grid-column: 2;
        color: var(--textColorPrimary);
    }

    & .saveButton {
        /* place the save/saving/saved buttons in the same grid area */
        grid-area: 1 / 1 / 1 / 1;
    }
}

.save-button-enter {
    opacity: 0;
    transform: scale(0.9);

    &.savedButton {
        color: var(--accentColorConfirmation);
    }
}

.save-button-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.save-button-enter-done.savedButton {
    color: var(--textColorSecondary);
    transition: color 5000ms ease;
}

.save-button-exit {
    opacity: 1;
}

.save-button-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.save-button-exit-done {
    visibility: hidden;
}
