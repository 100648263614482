.narrow {
    margin: var(--spacing60) 5%;
    position: relative;

    @media (width >= 37.5em) {
        margin: var(--spacing60) 18%;
    }
}

.full {
    margin: var(--spacing60) 5%;
    position: relative;

    @media (width >= 60em) {
        margin: var(--spacing60) 10%;
    }
}

.bleed {
    margin: var(--spacing60) 0;
    position: relative;
}

.narrowTop {
    margin: var(--spacing20) 5% var(--spacing60) 5%;
    position: relative;

    @media (width >= 60em) {
        margin: var(--spacing20) auto var(--spacing60);
        max-width: var(--breakWidth960);
    }
}

.narrowReader {
    position: relative;

    @media (width >= 46em) {
        padding: 0 4rem var(--spacing16);
    }

    @media print {
        padding: 0;
    }
}

@media (width >= 0em) and (width < 25em) {
    .full {
        margin: var(--spacing40) 5%;
    }
}
